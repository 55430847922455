import React from "react"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
// import Layout from "../components/Layout"
import Image from "gatsby-image"
import Layouts from "../components/Layout2"
// import { Helmet } from "react-helmet"


const About = ({data}) => {

  const {allStrapiAbout:{nodes:about}}=data

  const{title,info,stack,image,info2}=about[0];
  
  return (
    <Layouts>
     <Seo title="About" description="ABOUT PAGE MP2IT MP2ITHOME MP RESEARCH WORK MPRESEARCHWORK MPBS MP BUSINESS SOLUTION MPRW" />
      <section className="about-page" style={{padding:"7rem 0 7rem 0"}}>
       <div className="section-center about-center">
        <Image fluid={image.childImageSharp.fluid} className="about-img" alt="mp2it about image"/>
        <article className="about-text"> 
        <Title title={title}/>
        <p data-sal="slide-up" data-sal-duration="1000">{info}</p>
        {/* <div className="about-stack">
          {stack.map((item)=>{
           return<span key={item.id}>
              {item.title}
           </span>
          })}

        </div> */}
        </article>
        
        
       </div>
      
       <div className="section-center">
       <article className="about-text"> 
        <p style={{paddingTop:"20px"}}>{info2}</p>
        <div className="about-stack" data-sal="flip-down" data-sal-duration="1000">
          {stack.map((item)=>{
           return<span key={item.id}>
              {item.title}
           </span>
          })}

        </div>
        </article>
       </div>
      </section>
    </Layouts>
  )
}

export const query = graphql`
  {
    allStrapiAbout {
      nodes {
        stack {
          id
          title
        }
        title
        info
        info2
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default About
