import React, { useState } from "react"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import "../assets/css/main.css"
const Layout2 = ({ children }) => {
  const [isOpen,setisOpen]=useState(false);
  const toggleSidebar=()=>{
    setisOpen(!isOpen)
  }
  return <>
 <Navbar toggleSidebar={ toggleSidebar} cssclass={"nav-links check-link"} />
 <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
  {children}
  <Footer/>
    </>
}

export default Layout2
